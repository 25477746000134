import A from '../../images/A.jpg';
import B from '../../images/B.jpg';
import C from '../../images/C.jpg';
import D from '../../images/D.jpg';
import E from '../../images/E.jpg';
import F from '../../images/F.jpg';
import G from '../../images/G.jpg';
import H from '../../images/H.jpg';
import I from '../../images/I.jpg';
import J from '../../images/J.jpg';
import K from '../../images/K.jpg';
import L from '../../images/L.jpg';
import M from '../../images/M.jpg';
import N from '../../images/N.jpg';
import O from '../../images/O.jpg';
import P from '../../images/P.jpg';
import Q from '../../images/Q.jpg';
import R from '../../images/R.jpg';
import S from '../../images/S.jpg';
import T from '../../images/T.jpg';
import U from '../../images/U.jpg';
import V from '../../images/V.jpg';
import W from '../../images/W.jpg';
import X from '../../images/X.jpg';
import Y from '../../images/Y.jpg';
import Z from '../../images/Z.jpg';
import Za from '../../images/Za.jpg';
import Zb from '../../images/Zb.jpg';
import Zc from '../../images/Zc.jpg';
import Zd from '../../images/Zd.jpg';
import Ze from '../../images/Ze.jpg';
import Zf from '../../images/Zf.jpg';
import Zg from '../../images/Zg.jpg';
import Zh from '../../images/Zh.jpg';
import Zi from '../../images/Zi.jpg';
import Zj from '../../images/Zj.jpg';
import Zk from '../../images/Zk.jpg';
import Zl from '../../images/Zl.jpg';
import Zm from '../../images/Zm.jpg';
import Zn from '../../images/Zn.jpg';

import Logo from '../../Logo.png';
import HorizontalScroll from 'react-scroll-horizontal';
import GlitchClip from 'react-glitch-effect/core/GlitchClip';
import './Nfts.css';

const Nfts = () => {

  return(
    <div className='main-nft'>
    <p className='meet-the'>{/* Meet the */}<GlitchClip><img className='main-logo' src={Logo} /></GlitchClip></p>

    {/* <div className='first-release'>
    <p className='description-release'>
       <span className='alert'>First release coming <strong>February 14th</strong>, Valentine's day!</span>
       <br/>
       <br/>
       100 of the 2000 Punk-Kidz will be dropping in the first release.
       The purchase of 2 Punk-Kidz comes with a free custom made one (gas fees not included).
       <br/>
       <br/>
       A custom Punk-Kid allows one to choose their own features and accessories
       and will be a standard option for purchase in later stages.
    </p>
    </div> /*}

    {/* <HorizontalScroll style={{overflowX: 'scroll'}}> */}
      <div id='punkidz' className='images'>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047313933298565121'>
         <img className='nft' src={A} alt='a'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047345819135770625'>
         <img className='nft' src={B} alt='B'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047357913763676161'>
         <img className='nft' src={C} alt='C'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047333724507865089'>
         <img className='nft' src={D} alt='D'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047339222066003969'>
         <img className='nft' src={E} alt='E'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047372207414837249'>
         <img className='nft' src={F} alt='F'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047324928414842881'>
         <img className='nft' src={G} alt='G'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047350217182281729'>
         <img className='nft' src={H} alt='H'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047293042577637377'>
         <img className='nft' src={I} alt='I'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047300739159031809'>
         <img className='nft' src={J} alt='J'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047335923531120641'>
         <img className='nft' src={K} alt='K'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047315032810192897'>
         <img className='nft' src={L} alt='L'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047332624996237313'>
         <img className='nft' src={M} alt='M'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047338122554376193'>
         <img className='nft' src={N} alt='N'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047307336228798465'>
         <img className='nft' src={O} alt='O'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047310634763681793'>
         <img className='nft' src={P} alt='P'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047361212298559489'>
         <img className='nft' src={Q} alt='Q'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047377704972976129'>
         <img className='nft' src={R} alt='R'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047349117670653953'>
         <img className='nft' src={S} alt='S'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047326027926470657'>
         <img className='nft' src={T} alt='T'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047317231833448449'>
         <img className='nft' src={U} alt='U'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047379903996231681'>
         <img className='nft' src={V} alt='V'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047375505949720577'>
         <img className='nft' src={W} alt='W'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047319430856704001'>
         <img className='nft' src={X} alt='X'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047321629879959553'>
         <img className='nft' src={Y} alt='Y'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047318331345076225'>
         <img className='nft' src={Z} alt='Z'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047329326461353985'>
         <img className='nft' src={Za} alt='Za'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047306236717170689'>
         <img className='nft' src={Zb} alt='Zb'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047343620112515073'>
         <img className='nft' src={Zc} alt='Zc'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047294142089265153'>
         <img className='nft' src={Zd} alt='Zd'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047302938182287361'>
         <img className='nft' src={Ze} alt='Ze'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047304037693915137'>
         <img className='nft' src={Zf} alt='Zf'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047352416205537281'>
         <img className='nft' src={Zg} alt='Zg'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047340321577631745'>
         <img className='nft' src={Zh} alt='Zh'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047341421089259521'>
         <img className='nft' src={Zi} alt='Zi'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047346918647398401'>
         <img className='nft' src={Zj} alt='Zj'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047298540135776257'>
         <img className='nft' src={Zk} alt='Zk'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047397496182276097'>
         <img className='nft' src={Zl} alt='Zl'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047381003507859457'>
         <img className='nft' src={Zm} alt='Zm'/></a>

         <a target='_blank' href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/69330235437696246364692760290130211860341136259851325911636047353515717165057'>
         <img className='nft' src={Zn} alt='Zn'/></a>
      </div>
    {/* </HorizontalScroll> */}
    </div>
  )
}

export default Nfts;
