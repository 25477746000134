import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
       <div className='foot'>
        {/* <p>Copyright © 2022 punkkidz.com</p> */}
        <span className='email'><strong>Contact us:</strong> </span><a className="email" href = "mailto: info@punkkidz.com">info@punkkidz.com</a><br/>
        {/* <a href='https://www.instagram.com/punkkidz_/' target="_blank"><i class="fab fa-instagram icon insta"></i></a> */}       
       </div>
    </div>
  )
}

export default Footer;
