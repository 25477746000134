import './Navbar.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from '../../Logo.png';
import Opensea from '../../Opensea.png';

const Navbar = () => {
  return(
    <div className='main'>
       <div className='navbar'>
          <div className='left-nav'>
             <AnchorLink href='#main'> <img className='punkkidz-img' src={Logo}/> </AnchorLink>
          </div>
          <div className='right-nav'>
             <a className='glitch' href='https://opensea.io/collection/punk-kidz-1' target="_blank">OpenSea</a>
             <AnchorLink className='glitch' href='#punkidz'>Punk-Kidz</AnchorLink>
             <AnchorLink className='glitch' href='#about'>About</AnchorLink>
             <AnchorLink className='glitch' href='#team'>Team</AnchorLink>
             <AnchorLink className='glitch' href='#join'>Join</AnchorLink>
          </div>
          <div className='opensea-div'>
          <img className='sea sea-nav' src={Opensea} alt=''/>
          <a className='glitch open-link' href='https://opensea.io/collection/punk-kidz-1' target="_blank">OpenSea</a>
          </div>
       </div>
    </div>
  )
}

export default Navbar;
