import './Links.css'
import Boy from '../../Boy.png';
import Girl from '../../Girl.png';
import Opensea from '../../Opensea.png';

const Links = () => {
  return(
    <div className='links' id='join'>

    <div className='one'>
       <img className='girl' src={Girl} alt=''/>
    </div>

    <div className='two'>

      <p className='join'>Join us!</p>
      <div className='boxes'>
         <a href='https://opensea.io/collection/punk-kidz-1' target="_blank"><div className='opensea box'><img className='sea' height='46px' width='46px' src={Opensea} alt=''/> OpenSea</div></a>
         <a href='https://discord.gg/Jk92TCmDy5' target="_blank"><div className='discord box'><i class="fab fa-discord"></i> Discord</div></a>
         <a href='https://twitter.com/PunkKidzNFT' target="_blank"><div className='twitter box'><i class="fab fa-twitter"></i> Twitter</div></a>
         {/* <a href='https://www.instagram.com/punkkidz_/' target="_blank"><div className='opensea box'><i class="fab fa-instagram icon sea"></i> Instagram</div></a> */}
      </div>

      </div>

    </div>
  )
}

export default Links;
