import './About.css';

const About = () => {
  return(
    <div className='about' id='about'>
    <div className='text'>
       <p className='top-text'>About Punk-Kidz</p>
       <p className='description'><strong style={{color: "#03fca1"}}>Punk-Kidz</strong> is a collection of unique
        hand-drawn NFT characters. A group of young troublemakers who never fit
         in to regular society are now breaking into the NFT universe.
       <br/>
       <br/>
         Not only can collectors gain exclusive ownership from this collection, they will also have the option to be
         involved with the creative process and incorporate their own personal ideas in the creation of their own
         Punk-Kid.</p>
      </div>
      <hr/>
      <hr className='hr-pink'/>
      <hr/>
    </div>
  )
}

export default About;
